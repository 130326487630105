﻿.navbar-toggler {
  border: none;
}

.container {
  padding-top: 2rem;
}

.bmd-form-group {
  .checkbox {
    span.check {
      background: white;
    }
  }
}
