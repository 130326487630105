﻿.profile {

  .user-box {
    display: flex;

    .user-picture {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-right: 1rem;

      .user-picture-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem;
        padding: 1rem;
        border-radius: 50%;
        border: 2px solid black;
        width: 4rem;
        height: 4rem;

        i {
          font-size: 3rem;
        }
      }
    }

    .user-details {
      display: flex;
      flex-direction: column;
      justify-content: space-evenly;
      overflow: hidden;

      .user-name {
        text-overflow: ellipsis;
        overflow: hidden;

        @include media-breakpoint-down(xs) {
          font-size: 1.5rem;
        }
      }

      .user-email {
        text-overflow: ellipsis;
        overflow: hidden;
        font-size: 1.25rem;

        @include media-breakpoint-down(xs) {
          font-size: 1rem;
        }
      }
    }
  }

  .select-application-label {
    line-height: 2.5rem;
    font-weight: 600;
  }

  .application-roles-list {
    max-height: 50vh;
    overflow: auto;
  }
}
