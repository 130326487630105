﻿.roles {

  fieldset {
    padding: 1rem;
  }

  .role-status,
  .permission-select-col {
    width: 1em;
  }

  .alert {
    margin: 8px 0;
  }

  .add-role-link,
  .add-role-link:focus,
  .add-role-link:hover {
    outline: none;
    text-decoration: none;
  }

  .m-left-auto {
    margin-left: auto;
  }

	#roleFormError {
		display: none;
	}
}
