@import "variables.scss";
@import "theme.scss"; // This should be kept ABOVE bootstrap-material-design.scss import

@import "../../node_modules/bootstrap-material-design/scss/bootstrap-material-design.scss";
@import "custom-components.scss";

:root {
  --d3-primary-color: #2e3273;
  --d3-secondary-color: #9294a9;
  --d3-tertiary-color: #f75258;
  --d3-text-menu-color: #fff;
  --d3-light-blue-color: #5371f7;
  --d3-primary-button-color: #f75258;
  --d3-primary-button-text-color: #fff;
}

html,
body {
  height: 100%;
}

.page-content {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 40px;
  padding-bottom: 40px;
}

.card-600 {
  max-width: 600px;
}

.navbar-right {
  display: flex;
}

.navbar-breadcrumb {
  @include media-breakpoint-down(xs) {
    display: none;
  }
}

.guishell-avatar {
	border: 1px solid #63a4ff;
	background-color: #004ba0;
	color: #e9ecef;
	cursor: pointer;
	border-radius: 50%;
	padding: 0;
	height: 2.5rem;
	width: 2.5rem;
	text-align: center;
	line-height: 2.5rem;
	font-weight: 500;
}

.version-container {
  margin: 0 auto;

  &.full-body {
    margin: -24px 0;
    text-align: center;
  }
}

.application-logo {
  max-height: $application-logo-size;
  max-width: $application-logo-size;
}

.signout {
  display: none;
}

.w-30 {
  width: 30%!important;
}

.w-60 {
  width: 60%!important;
}

// These should be kept BELOW bootstrap-material-design.scss import

@import "home.scss";
@import "roles.scss";
@import "profile.scss";
@import "drawer.scss";

@import "overrides.scss";
