.btn-icon-text {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 8px;

  & > i {
    margin-right: 8px;
  }

  & > span {
    margin-top: 1px;
  }
}

.icon-search-input-group {
  padding-top: 0;
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  max-width: 40rem;

  .icon-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0.5rem;
  }

  .bmd-btn-icon {
    margin-top: 0.2rem;
    position: absolute;
    right: 0;
    display: none;
  }

  input {
    flex: 1 1 auto;
  }
}

.application-detail {

  .application-logo {
    max-width: 100%;
  }

  .application-name {
    text-overflow: ellipsis;
    overflow: hidden;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.25rem;
    }
  }

  .application-url {
    @include media-breakpoint-down(sm) {
      font-size: 1.25rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1rem;
    }
  }

  .parent-application-name {
    text-overflow: ellipsis;
    overflow: hidden;
    font-weight: 300;

    @include media-breakpoint-down(md) {
      font-size: 2rem;
    }

    @include media-breakpoint-down(sm) {
      font-size: 1.5rem;
    }

    @include media-breakpoint-down(xs) {
      font-size: 1.25rem;
    }
  }
}

.hiddencount-display {
  padding: 0.2rem;
  text-align: center;
  color: var(--gray);
  font-size: .75rem;
  height: 1rem;
  visibility: hidden;
}

.list-group-table {

  .list-group-item {
    flex-flow: row nowrap;

    :first-child {
      margin-right: 0;
    }

    &.list-group-item-compact {
      padding: 0.5rem;
    }

    &:hover {
      background-color: theme-color("light");
    }

    &.list-group-item-header {
      background-color: theme-color("background-light");
    }

    .bmd-form-group {
      padding: 0;
      height: 32px;
      width: 32px;
    }

    .bmd-list-group-col {
      flex: 1 1 auto;
      flex-direction: row;
      overflow: hidden;
    }

    .list-group-item-heading {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-bottom: .25rem;

      @include media-breakpoint-up(lg) {
        margin: 0;
        width: calc((100% - 0.5em) / 2);
      }
    }

    .list-group-item-text {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin-top: .25rem;
      margin-bottom: .25rem;

      @include media-breakpoint-up(lg) {
        margin: 0;
        margin-right: 1rem;
        width: calc((100% - 0.5em) / 2);
      }
    }

    @include media-breakpoint-down(md) {
      .list-group-item-heading {
        width: auto;
        max-width: initial;
      }

      .bmd-list-group-col {
        flex-direction: column;
      }
    }

    .bmd-btn-icon {
      display: flex;
      justify-content: center;
      align-items: center;

      i {
        margin: 0;
      }
    }
  }

  &.list-group-table-3-rows {
    .list-group-item-text {
      @include media-breakpoint-up(lg) {
        margin: 0;
        margin-right: 1rem;
        width: calc((100% - 0.5em) / 4);
      }
    }
  }
}

#auto-dismissing-alert {
  position: fixed;
  z-index: $zindex-popover;
  width: 100%;
}

.empty-icon {
  width: 24px;
}

.empty-icon-parent-application {
  width: 32px;
}

form {
  margin-bottom: 0px;
  padding: 0px;
}

delete-form-button {
  margin-left: 5px;
}

.error-image {
  display: block;
  width: 80%;
  height: 80%;
}