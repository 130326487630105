﻿
.empty-state-container {
	display: flex;
	align-items: center;
	justify-content: center;
}

.application-card {
	margin: 16px auto;
	width: $application-logo-size;

	& > a {
		height: $application-logo-size;
	}

	.application-card-body {
		display: flex;
		flex-direction: row;
		padding: 8px;

		.application-card-text {
			flex: 1;
			flex-direction: column;
			overflow: hidden;

			h5, div {
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
			}
		}
	}
}
