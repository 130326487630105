body {
  background-color: white;
  overflow: hidden;
  font-family: "Montserrat", sans-serif;
}

.drawer-header {
  padding: 16px 24px 24px;

  .search-input-container {
    position: relative;
    border: 1px solid #d3d7d9;
    border-radius: 2px;
    transition: all 0.3s ease;

    &:hover {
      border: 1px solid #b8bec2;
    }

    &:focus-within {
      border: 1px solid #53cedc;
    }
  }

  #filter-application-input {
    background-image: none;
    padding-left: 16px;

    &:focus {
      box-shadow: none;
    }
  }

  .filter-icon {
    margin: auto 8px;
    color: #b8bec2;
  }
}

.drawer-scrollable {
  overflow: auto;
  flex: 1;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.1) transparent;
}

#application-list-container {
  display: flex;
}

#empty-state {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.drawer-error-title {
  font-weight: 500;
  font-style: normal;
  font-size: 16px;
  line-height: 19.5px;
}

.drawer-error-text {
  font-weight: 300;
  font-style: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #5f676d;
  margin: 0 24px;
}

.drawer-error-button {
  border-radius: 2px;
  border: 1px solid #f75258;
  color: #f75258;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  text-transform: uppercase;
  padding: 13px 20px;
  margin-top: 24px;
}

.drawer-error-button:focus {
  background-color: rgba(0, 0, 0, 0);
  border: 1px solid #f75258;
  color: #f75258;
}

.drawer-title {
  display: inline-block;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #303336;
  margin-bottom: 16px;
}

.drawer-app-item {
  color: initial;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 116px;
  width: 104px;
  padding-top: 24px;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

.drawer-app-item:hover {
  cursor: pointer;
  color: initial;
  background-color: #f8f8f8;
}

.drawer-app-name {
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  color: #303336;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin: 18px 8px 0;
}

.drawer-app-thumbnail {
  height: 32px;
  width: 32px;
}

.drawer-all-apps-label {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #303336;
}

.drawer-all-apps-arrow {
  color: #53cedc;
  margin-left: 6px;
  margin-bottom: 2px;
  font-size: 20px;
}

.drawer-search-clear-icon {
  position: fixed;
  right: 20px;
}

/* SCROLLBAR */
::-webkit-scrollbar {
  width: 14px;
  height: 18px;
}

::-webkit-scrollbar-thumb {
  height: 6px;
  border: 4px solid rgba(0, 0, 0, 0);
  background-clip: padding-box;
  -webkit-border-radius: 7px;
  background-color: rgba(0, 0, 0, 0.15);
  -webkit-box-shadow: inset -1px -1px 0px rgba(0, 0, 0, 0.05),
    inset 1px 1px 0px rgba(0, 0, 0, 0.05);
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
  display: none;
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}
